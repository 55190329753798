<script>
    export let rows = 5;
    export let columns = 3;
    export let headerHeight = 'h-10';
    export let rowHeight = 'h-12';
    
    import * as Table from "$lib/components/ui/table";
    import * as Skeleton from "$lib/components/ui/skeleton";
</script>

<Table.Root>
    <Table.Header>
        <Table.Row>
            {#each Array(columns) as _, i}
                <Table.Head>
                    <Skeleton.Root class="{headerHeight} w-full" />
                </Table.Head>
            {/each}
        </Table.Row>
    </Table.Header>
    <Table.Body>
        {#each Array(rows) as _, i}
            <Table.Row>
                {#each Array(columns) as _, j}
                    <Table.Cell>
                        <Skeleton.Root class="{rowHeight} w-full" />
                    </Table.Cell>
                {/each}
            </Table.Row>
        {/each}
    </Table.Body>
</Table.Root>